import React, { useState } from "react";
import Button from "../../common/Button/Button";
import "./Lightbox.css"; // Assuming CSS for lightbox is in this file

const MakeAnImpact = () => {
  const [showLightbox, setShowLightbox] = useState(false);

  const handleLightboxToggle = () => {
    setShowLightbox(!showLightbox);
  };

  return (
    <div className="makeAnImpact" data-aos="fade-right">
      <h1>
        Make an Impact! <br />
        Donate Now
      </h1>
      <div className="donateSection" data-aos="fade-up">
        <p>
          Thanks to the generosity of our regular members and donors, we have
          raised over $120,000 since 2020. Contributions of any size make a
          difference, significantly improving the schools and lives of the
          communities that greatly need our help.
        </p>
        <div className="hero-btns">
          <div className="hero-btn-group">
            {/* Replacing original button with lightbox trigger */}
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--large"
              onClick={handleLightboxToggle}
            >
              UNITED STATES
            </Button>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--large"
              src="https://www.paypal.com/paypalme/atiqrahin"
              newTab="true"
            >
              INTERNATIONAL
            </Button>
          </div>
        </div>
      </div>

      {/* Lightbox Popup */}
      {showLightbox && (
        <div className="lightbox">
          <div className="lightbox-content">
            <button
              className="close-button"
              onClick={handleLightboxToggle}
              aria-label="Close"
            >
              &times;
            </button>
            <h2>Payment Information</h2>
            <p>Zelle: 609-540-3266</p>
            <p>Venmo: @Atiq-Rahin</p>
            <p>PayPal: @AtiqRahin</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MakeAnImpact;
